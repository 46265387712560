import styled from 'styled-components'
import { colors, fonts, media } from '../../../lib/constants'

export const Heading = styled.h2`
  font-size: 48px;
  line-height: 1.2;
  font-family: ${fonts.heading};
  font-weight: 700;
  color: ${colors.white};

  ${media.tablet`
    padding-top: 4rem;
    text-align: center;
  `}
`

export const Content = styled.div`
  color: ${colors.white};
  font-weight: 400;

  ${media.tablet`
    text-align: center;
    margin-bottom: 1.75rem;
  `}
`

export const Box = styled.div`
  color: ${colors.white};
  max-width: 595px;

  ${media.desktop`
    max-width: 100%;
  `}

  ${media.tablet`
    a {
      margin-bottom: 1rem;
    }
  `}
`
