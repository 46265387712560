import styled from 'styled-components'
import { colors, media } from '../../../../lib/constants'

export const ContactForm = styled.div`
  padding-left: 20px;
  border-left: 1px dashed rgba(255, 255, 255, 0.5);

  button {
    margin-top: 1.25rem;
    min-width: 130px;
  }

  form {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 30px;
    color: ${colors.white};
    margin-bottom: 0;
    border-radius: 10px;
  }

  ${media.wide`
    margin-top: 40px;
    padding-top: 40px;
    padding-left: 0;
    border-left: none;
    border-top: 1px dashed rgba(255, 255, 255, 0.5);
    button {
      display: block;
      margin: 1.25rem auto 0;
    }
  `}
`

export const Input = styled.input`
  border: solid 1px ${colors.grey};
  border-radius: 3px;
  padding: 0.5rem 1rem;
  margin: ${({ margin }) => margin || `0 0 1.5rem`};
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: ${colors.white};

  &:focus {
    outline: none;
  }
`

export const Textarea = styled.textarea`
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 0.5rem 1rem;
  margin: ${({ margin }) => margin || `0 0 1.5rem`};
  width: 100%;
  color: ${colors.white};

  &:focus {
    outline: none;
  }
`

export const Heading = styled.p`
  ${media.tablet`
    text-align: center;
  `}
`

export const SuccessText = styled.p`
  margin: 1rem 0 0;
  display: block;
  color: ${colors.white};
`
export const ErrorText = styled.p`
  margin: 1rem 0 0;
  display: block;
  color: ${colors.white};

  a {
    color: ${colors.white};
    text-decoration: underline;
  }
`
