import React, { useState } from 'react'
import { Row, Col } from '../../../layouts/grid.css'
import Button from '../../Global/Button'
import BounceLoader from 'react-spinners/BounceLoader'
import * as S from './Form.css'
import { colors } from '../../../../lib/constants'
import { sendForm } from '../../../../lib/helpers'

const emptyFormData = {
  name: '',
  phone: '',
  email: '',
  comments: '',
}

const ContactForm = () => {
  const [isLoading, setLoading] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [isError, setError] = useState(false)
  const [formData, setFormData] = useState(emptyFormData)

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const result = await sendForm('contact', formData)

    if (result.ok) {
      setSuccess(true)
      setFormData(emptyFormData)
      setLoading(false)
    } else {
      setLoading(false)
      setError(true)
    }
  }

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <S.ContactForm>
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        onSubmit={(e) => handleFormSubmit(e)}
      >
        <input type="hidden" name="form-name" value="contact" />
        <Row gutter={10}>
          <Col md={12}>
            <S.Input
              required
              onChange={(e) => handleInputChange(e)}
              style={{ flexGrow: 1 }}
              type="text"
              placeholder="Your name"
              name="name"
              half
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={6}>
            <S.Input
              required
              onChange={(e) => handleInputChange(e)}
              style={{ flexGrow: 1 }}
              type="text"
              placeholder="Phone number"
              name="phone"
              half
            />
          </Col>
          <Col md={6}>
            <S.Input
              required
              onChange={(e) => handleInputChange(e)}
              style={{ flexGrow: 1 }}
              type="text"
              placeholder="Email address"
              name="email"
              half
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <S.Textarea
              onChange={(e) => handleInputChange(e)}
              style={{ flexGrow: 1 }}
              type="text"
              rows="5"
              placeholder="Message..."
              name="comments"
              margin="0"
            />
          </Col>
        </Row>
        <Button type="submit">
          {isLoading ? <BounceLoader color={colors.white} size={22} /> : 'Send'}
        </Button>
        {isSuccess && (
          <S.SuccessText>
            Thanks for saying Hi! We'll be in touch soon.
          </S.SuccessText>
        )}
        {isError && (
          <S.ErrorText>
            There was a problem submitting your form, send us an email at{' '}
            <a href="mailto:contact@techequipt.com.au">
              contact@techequipt.com.au
            </a>
            .
          </S.ErrorText>
        )}
      </form>
    </S.ContactForm>
  )
}

export default ContactForm
