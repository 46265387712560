import React from 'react'
import { graphql } from 'gatsby'
import htmr from 'htmr'
import { Layout, Container } from '../layouts'
import { Helmet } from 'react-helmet'
import { Row, Col } from '../layouts/grid.css'
import ContactForm from '../components/Contact/Form'
import Button from '../components/Global/Button'
import CTA from '../components/Global/CTA'
import { colors } from '../../lib/constants'
import Phone from '../components/Global/Icon/Phone'
import Talk from '../components/Global/Icon/Talk'

import * as S from '../components/Contact/Contact.css'

const SayHiPage = ({ data }) => {
  const { html, frontmatter } = data.pageData
  const { title, cta } = frontmatter
  return (
    <Layout>
      <Helmet>
        <title>
          Have an exciting app or web-based idea - contact TechEquipt
        </title>
        <meta
          name="description"
          content="Contact TechEquipt, we are a company that cares, we provide awesome web & app solutions, and want to hear about new builds, ongoing work, and your exciting idea"
        />
      </Helmet>
      <Container color={colors.blue} padding="8rem 0 4rem">
        <Row gutter={10}>
          <Col lg={6}>
            <S.Heading>{title}</S.Heading>
            <S.Content>{htmr(html)}</S.Content>
            <S.Box>
              <Row gutter={10}>
                <Col md={6}>
                  <Button type="link" href="tel:+61419413884" block>
                    <Phone />
                    <span>Call us now</span>
                  </Button>
                </Col>
              </Row>
            </S.Box>
          </Col>
          <Col lg={6}>
            <ContactForm />
          </Col>
        </Row>
      </Container>

      {cta.title && cta.image.publicURL && (
        <Container color={colors.blue} padding="0">
          <CTA
            rowReverse
            roundedImage
            heading={cta.title}
            content={cta.content}
            btnText={cta.button_text}
            btnLink={cta.button_link}
            image={cta.image.publicURL}
            btnIcon
          />
        </Container>
      )}
    </Layout>
  )
}

export default SayHiPage

export const pageQuery = graphql`
  query SayHiPageTemplate {
    pageData: markdownRemark(
      frontmatter: { templateKey: { eq: "sayhi-page" } }
    ) {
      id
      html
      frontmatter {
        slug
        title
        cta {
          title
          content
          image {
            publicURL
          }
          button_text
          button_link
        }
      }
    }
  }
`
